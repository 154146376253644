.signUp.simple-keyboard.hg-layout-default .hg-button .hg-row{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  align-items: center;
  background: #EBEDED;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  border-radius: 0.8rem;
  height: 3rem;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
  width: 1.2vw !important;
}

.signUp.hg-theme-default {
  background-color: #fff !important;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}
.signUp.hg-theme-default .hg-row .hg-button{
-webkit-tap-highlight-color: rgba(0,0,0,0);
align-items: center;
background: #EBEDED;
border-radius: 5px;
box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
box-sizing: border-box;
cursor: pointer;
display: flex;
font-size: 1.1rem;
border-radius: 0.8rem;
height: 3rem;
justify-content: center;
padding: 0px;
margin: 0.2rem;
width: 1.2vw !important;
}
.signUp.simple-keyboard.hg-layout-default .hg-button.hg-bksp {
  width: 1.2vw;
  background-image: url("../../../../assets/images/backspac.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% 40%;
}

.signUp.simple-keyboard.hg-layout-default .hg-button.hg-bksp >span{
  visibility: hidden;
  width: 1.2vw;
}

.signUp.simple-keyboard.hg-layout-default .hg-button.hg-space{
  width: 19vw !important;
}

.signUp.hg-theme-default .hg-row .hg-button.hg-bksp{
  width: 1.2vw;
  background-image: url("../../../../assets/images/backspac.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% 40%;
}

.signUp.hg-theme-default .hg-row .hg-button.hg-bksp > span{
  visibility: hidden;
  width: 1.2vw;
}

.signUP.hg-theme-default .hg-button{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  align-items: center;
  background: #EBEDED;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  border-radius: 0.8rem;
  height: 3rem;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
  width: 1.2vw !important;
}


.mainOverFlow {
  overflow: hidden;
}

.productSpecImgCenter {
  align-self: center;
}

.timePicker {
  width: 100%;
}
.datePicker {
  display: flex;
}

.section-btn {
  width: 100%;
  background-color: rgb(81, 173, 154);
  border: none;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  text-align: center;
  height: 4vw;
}

.modal-btn {
  width: 48% !important;
  height: 4vw;
}

